.learn-card {
  text-align: center;
  height: 350px;
  margin: 13px 0px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.5s cubic-bezier(.25,.8,.25,1);
}

.learn-card:hover {
  background: #00769F;
  box-shadow: 0 3px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.learn-card:hover .icon {
  background:#ffffff;
}

.learn-card:hover h5,
.learn-card:hover p {
  color: #ffffff;
}

.learn-card .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  background:#F2F2F2;
  height: 70px;
  width: 70px;
  margin-bottom: 15px;
}

.learn-card img {
  height: 22px;
}

.learn-card h5 {
  text-transform: uppercase;
  color: #00D0EF;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.3em;
}
