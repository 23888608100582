.bg-light {
  background: #fff !important;
}
.banner {
  background-color: #fff !important;
  // margin-top: 0px !important;
  margin-bottom: 20px;
  // padding-top: 0px !important;
}

.navbar-fixed {
  font-size: 36px;
  text-decoration: none !important;
  position: fixed;
  background: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  // padding: 20px 0;
  transition: all 0.5s cubic-bezier(.25,.8,.25,1);
}

.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin: 1rem;
}
.link {
  margin-right: 45px !important;
  // text-decoration: none;
}

.navbar a {
  text-decoration: none;
  font-size: 15px;

}

// // .first-nav