.intro-section {
  padding: 25px 0 100px 0;
}

.intro-section h1 {
  text-transform: uppercase;
}

.intro-section .-margin {
  margin-bottom:-140px;
}

.intro-section .descriptions {
  margin-top: 2px;
}

@media screen and (max-width: 600px) {
  .intro-section .descriptions {
    margin-top: 200px;
  }
}
