.sched-section {
  background: #00769F;
  padding: 80px 0px;
}

.sched-section .description {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sched-section img {
  margin-bottom: 20px;
}

.sched-section h5 {
  color: #ffffff;
  text-transform: uppercase;
}

.sched-section .meta {
  color: #ffffff;
}

.sched-section .btn {
  background: #ffffff;
  color: #717171;
}
