.navbar {
  padding: 30px;
}

@media screen and (max-width: 991px) {
  .my-2 {
    display: flex;
    flex-direction: column;
  }

  .my-2 a {
    margin: 15px 0;
  }
}
