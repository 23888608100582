html {
  scroll-behavior: smooth;
}

body {
  transition: all 0.4s cubic-bezier(.25,.8,.25,1);
  width: 100%;
  font-family: 'Roboto', sans-serif;

}

h1,
h2,
h3,
h4,
h5,
h6,
p,
a {
  letter-spacing: .57px;
  text-decoration: none;
}

.navbar,
.banner,
.providers-section,
.expand-section,
.sched-section,
.testimonial-section,
.contact-form,
.footer {
  overflow: hidden;
}

.heading {
  font-size: 47px;
  color: #00769F;
  text-transform: uppercase;
}

.section-title {
  color: #00759f;
  width: 330px;
  margin-bottom: 40px;
  font-weight: bold;
}

.sub {
  color: #C1C1C1;
}

.meta {
  color: #585858;
  line-height: 1.5em;
  font-size: 14px;
}
