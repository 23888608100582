.footer {
  background: #FAFAFA;
  padding: 80px 0px;
}

.footer .section {
  margin-bottom: 30px;
}

.footer .logo {
  margin-bottom: 20px;
}

.footer .links ion-icon {
  margin-right: 30px;
  font-size: 26px;
  color: #6c6c6c;
}

.footer .links ion-icon:hover {
  color: #00d0ef;
  cursor: pointer;
}

.footer .title {
  color: #00769F;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 20px;
}

.footer .link {
  margin-bottom: 20px;
  text-decoration: none;
}
