.testimonial-section {
  padding: 80px 0px;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 20px;
}

.testimonial-card .avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 120px;
  width: 120px;
  border-radius: 50%;
  background: #f7f7f7;
  margin-bottom: 20px;
}

.testimonial-card .avatar img {
  height: 62px;
}

.testimonial-card .name {
  font-weight: bold;
  color: #6DCFF6;
  margin-bottom: 20px;
}

.testimonial-card .position {
  color: #B0B1B4;
  margin-bottom: 5px;
  font-size: 13px;
}
