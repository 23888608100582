.expand-section {
  background: #ffffff;
  padding: 80px 0px;
}

.card-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.expand-card {
  margin: 15px 0px;
  padding: 51px;
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  box-shadow: none;
  transition: all 0.5s cubic-bezier(.25,.8,.25,1);
}

.expand-card:hover {
  padding: 51px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border: 1px solid #F2F2F2;
  border-radius: 5px;
  box-shadow: 0 3px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);

}

.expand-card .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  width: 80px;
  border-radius: 50%;
  overflow: hidden;
  background: #ffffff;
  margin-bottom: 32px;
}

.expand-card .list-icon {
  height: 22px;
}

.expand-card .person-icon,
.expand-card .speaker-icon,
.expand-card .line-icon {
  height: 32px;
}

.expand-card img {
  height: 42px;
}

.expand-card h6 {
  color: #00769F;
  text-transform: uppercase;

}
