.navbar a {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: .57px;
}

.link {
  color: #585858;
  transition: all 0.5s cubic-bezier(.25,.8,.25,1);
}

.link.-blue {
  color: #00D0EF;
  text-decoration: underline;
  cursor: pointer;
}

.link:hover,
.link:active {
  color: #00D0EF;
}

.cta-btn {
  padding: 10px;
  width: 150px;
 background: #00D0EF;
 color: #ffffff;
 font-size: 12px;
 text-transform: uppercase;
 box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
 transition: all 0.5s cubic-bezier(.25,.8,.25,1);
}

.cta-btn:hover {
 background: #00D0EF;
 color: #ffffff;
 box-shadow: 0 3px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.input-btn {
  width: 100%;
  border: 1px solid #C5C5C5;
  color: #585858;
  font-size: 14px;
}

form .cta-btn {
  width: 100%;
}
