.banner {
  margin-top: 60px;
  padding-bottom: 50px;
  padding-top: 80px;
}

.banner .left {
  margin-bottom: 15px;
}

.banner h1 {
  color: #00769F;
  font-size: 52px;
  line-height: 1em;
}

.banner .banner-image {
  object-fit: cover;
  overflow: hidden;
}

.banner img {
  width: 100%;
}
