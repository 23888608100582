.preloader {
  z-index: 99999;
  background: #ffffff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: fadeOut 3s;
  animation-fill-mode: forwards;
  transition: all 0.5s cubic-bezier(.25,.8,.25,1);
}

.loader img {
  width: 45px;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;

}

.spinner-bg {
  margin: 50px;
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.spinner-bg:after,
.spinner-bg:before {
  content: "";
  display: block;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.button-hover:before,
.button-hover:after {
  background: #00D0EF;
  animation: pulse 2s linear infinite;
  position: absolute;
}

.button-hover:after {
  animation: pulse 3s linear infinite;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  80% {
    opacity: 1;
  }

  85% {
    opacity: .5;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 1;
  }
  100% {
    transform: scale(1.1);
    opacity: 0;
  }
}
